
import { eventBus } from '@/assets/js/bus';
const Cookie = process.client ? require('js-cookie') : undefined;

export default {
  components: {
    CustomerCapture: () => import('@/components/CustomerCapture'),
  },
  props: {
    hide: {
      type: Function,
    },
  },
  mounted() {
    eventBus.$on('showFavoritesModal', this.showModal);
    eventBus.$on('hideFavoritesModal', this.hideModal);
    eventBus.$on('hideFavoritesModalWithCookie', this.hideModalWithCookie);
  },
  beforeDestroy() {
    eventBus.$off('showFavoritesModal', this.showModal);
    eventBus.$off('hideFavoritesModal', this.hideModal);
    eventBus.$off('hideFavoritesModalWithCookie', this.hideModalWithCookie);
  },
  methods: {
    showModal() {
      if (this.$refs.favoritesModal) this.$refs.favoritesModal.show();
    },
    hideModal() {
      this.hide ? this.hide() : this.$refs.favoritesModal.hide();
    },
    hideModalWithCookie() {
      Cookie.remove('productId');
      this.$refs.favoritesModal.hide();
    },
  },
};
