import { toUrlSlug } from '@/utils/url';
import { encodeOpts } from '@/utils/encode-opts';

const createQuery = (selectedFacets, facets) => {
  let facetsQuery = {};
  const facetsToFilter = _.cloneDeep(selectedFacets);
  for (let [key, value] of Object.entries(facetsToFilter)) {
    value = Array.isArray(value) ? value : Object.keys(value).filter((el) => value[el]);
    facetsToFilter[key] = value.filter((i) => i);
    if (facetsToFilter[key].length) {
      facetsQuery[toUrlSlug(key)] = value
        .filter((i) => i)
        .map((i) => facets.find((f) => f._id === i).slug)
        .join(',');
    }
  }
  return { facetsQuery, facetsToFilter };
};
const createQuery1 = (selectedFacets, facets) => {
  let facetsQuery = {};
  const facetsToFilter = _.cloneDeep(selectedFacets);
  for (const [key, value] of Object.entries(facetsToFilter)) {
    facetsToFilter[key] = value.filter((i) => i);
    if (facetsToFilter[key].length) {
      facetsQuery['selected'] = value
        .filter((i) => i)
        .map((i) => toUrlSlug(facets.find((f) => f._id === i).name))
        .join(',');
    }
  }
  return { facetsQuery, facetsToFilter };
};

const checkFacets = ({ facetGroupMap, filterFacets, facets }, facetGroupList) => {
  for (let i = 0; i < facetGroupList.length; i++) {
    const group = facetGroupList[i];
    if (!facetGroupMap[group.slug]) {
      filterFacets[group.slug] = [];
      facetGroupMap[group.slug] = {};
    }

    if (facets && group.facets) {
      facets.push(...group.facets);
    }
  }
  facets.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
};
function max_matches(groupName, mainFacet, isFacet = false) {
  try {
    if (groupName && groupName.length > 1) {
      if (isFacet) {
        groupName = groupName
          .map((el) => ({
            ...el,
            matches: el.name
              .replace(/\W/g, '')
              .toLowerCase()
              .match(mainFacet),
          }))
          .filter((el) => el.matches)
          .sort((a, b) => (a.matches > b.matches ? 1 : -1))[0];
      } else {
        groupName = groupName
          .map((el) => ({
            name: el,
            matches: el
              .replace(/\W/g, '')
              .toLowerCase()
              .match(mainFacet),
          }))
          .filter((el) => el.matches)
          .sort((a, b) => (a.matches > b.matches ? 1 : -1))[0].name; //sort with most matches
      }
    } else {
      groupName = groupName[0];
    }
    return groupName;
  } catch (e) {
    console.log(e);
    return false;
  }
}
const reservedKeys = [
  'withPartIdSearch',
  'withColorSearch',
  'suppliersToFilter',
  'inventory',
  'search',
  'closeout',
  'no-minimum',
  'query',
  'page',
  'price',
  'name',
  'sortBy',
  'supplier',
].map((el) => el.toLowerCase());
const setFacetsFilter = async (context, params, sortItems, facetGroups) => {
  let selectedSort = sortItems[0];
  const filterFacets = {};
  const getParams = Object.assign({}, params);
  getParams.sortFilter = selectedSort;
  let facetsCount = 0;
  let facets = [];

  let facetGroupMap = {};
  let path = context.route.params.pathMatch ? context.route.params.pathMatch.toLowerCase() : '';
  const lowerPath = context.route.path ? context.route.path.toLowerCase() : '';
  if (lowerPath.match('/products/search')) {
    path = '';
  } else if (lowerPath.match('/products/closeout')) {
    path = lowerPath
      .split('/products/closeout')[1]
      .split('/')
      .splice(1)
      .join('/');
  } else if (lowerPath.match('/products/no-minimum')) {
    path = lowerPath
      .split('/products/no-minimum')[1]
      .split('/')
      .splice(1)
      .join('/');
  } else if (lowerPath.match('/cat/')) {
    if (!context.route.params.pathMatch || !context.route.params.slug) {
      path = path
        .split('/')
        .splice(1)
        .join('/');
    }
  } else if (lowerPath.match('/supplier/')) {
    if (!context.route.params.pathMatch || !context.route.params.psCode) {
      path = path
        .split('/')
        .splice(1)
        .join('/');
    }
  }

  const mainFacet = path ? toUrlSlug(path.split('/')[0]) : false;
  let faceGroupsToCheck = [];
  if (mainFacet && reservedKeys.indexOf(mainFacet) < 0) {
    let filter = path.split('/');
    if (filter[1]) {
      filter = toUrlSlug(filter[1]);
    } else if (mainFacet.toLowerCase() === toUrlSlug(filter).toLowerCase()) {
      filter = '';
    } else {
      filter = toUrlSlug(filter);
    }
    faceGroupsToCheck.push({
      facetGroup: mainFacet.toLowerCase(),
      facets: (filter && filter.toLowerCase().split(',')) || [],
    });
  }
  const entries = Object.entries(context.route.query).filter(([key]) => !['noCachePage', 'draft'].includes(key));

  for (const [key, value] of entries) {
    const sortBy = sortItems.find((i) => value && (i.label === value || i.query === value));
    if (sortBy) {
      selectedSort = sortBy;
      getParams.sortFilter = selectedSort;
    }
    if (!key || !value || reservedKeys.indexOf(key.toLowerCase()) > -1) {
      continue;
    }
    faceGroupsToCheck.push({
      facetGroup: key.toLowerCase(),
      facets: value && value.toLowerCase().split(','),
    });
  }

  faceGroupsToCheck = faceGroupsToCheck.filter(({ facetGroup }) => {
    const name = (facetGroup || '').toLowerCase();
    return !name.match(/^(utm_|_hs|gclid)/) && !name.match(/(version)/);
  });
  if (faceGroupsToCheck.length) {
    facetGroups = (
      await context.$axios.get(`/facetGroups`, {
        params: { slug: faceGroupsToCheck.map((el) => el.facetGroup) },
        paramsSerializer: encodeOpts,
      })
    ).data;
    const facetsSLugs = faceGroupsToCheck.map((el) => el.facets).reduce((a, b) => [...a, ...b], []);
    const params = {
      params: {
        ...((facetsSLugs && facetsSLugs.length && { slug: facetsSLugs }) ||
          (faceGroupsToCheck.length && { facetGroup: facetGroups.map((e) => e._id) })),
      },
      paramsSerializer: encodeOpts,
    };
    facets = (await context.$axios.get(`/facets`, params)).data.filter((e) => !e.inActive);
    facetGroups?.forEach((group) => {
      filterFacets[group.slug] = [];
      facetGroupMap[group.slug] = {};
    });
  }
  for (let i = 0; i < faceGroupsToCheck.length; i++) {
    const groupItem = faceGroupsToCheck[i];
    const group = facetGroups.find((g) => g.slug === groupItem.facetGroup);
    if (group && groupItem.facets) {
      for (let j = 0; j < groupItem.facets.length; j++) {
        let filter = groupItem.facets[j];

        const facet = facets.find((f) => f.slug === filter && f.facetGroup === group._id);
        if (facet) {
          if (!facetsCount) {
            facetsCount = 1;
          } else {
            facetsCount++;
          }
          facetGroupMap[group.slug][facet._id] = facet._id;
          if (!getParams.facetsToFilter[group.slug]) {
            getParams.facetsToFilter[group.slug] = [];
            filterFacets[group.slug] = [];
          }
          getParams.facetsToFilter[group.slug].push(facet._id);
          filterFacets[group.slug].push(facet._id);
        } else {
          getParams.facetsToFilter[group.slug] = facets.filter((e) => e.facetGroup === group._id).map((e) => e._id);
          break;
        }
      }
      if (!groupItem.facets.length) {
        getParams.facetsToFilter[group.slug] = facets.filter((e) => e.facetGroup === group._id).map((e) => e._id);
      }
    } else {
      // getParams.facetsToFilter['-1'] = [];
      break;
    }
  }

  return { filterFacets, getParams, selectedSort, facets, facetsCount, facetGroupMap };
};

const setFacetsFilter1 = async (context, params, sortItems) => {
  let selectedSort = sortItems[0];
  const filterFacets = {};
  const getParams = Object.assign({}, params);
  getParams.sortFilter = selectedSort;
  let facetsCount = 0;
  const facets = (await context.$axios.get('/facets')).data.filter((e) => !e.inActive);
  let facetGroups = (await context.$axios.get('/facetGroups')).data;
  const allFacetGroups = [...facetGroups];
  let facetGroupMap = {};
  getParams.facetsToFilter = {};
  facetGroups.forEach((group) => {
    filterFacets[group.slug] = [];
    facetGroupMap[group.slug] = {};
    getParams.facetsToFilter[group.slug] = facets.filter((el) => el.facetGroup === group._id).map((el) => el._id);
  });
  let hasFacets = false;
  if (context.route.params.slug) {
    const mainFacet = toUrlSlug(context.route.params.slug);
    let groupName = Object.keys(facetGroupMap).find((f) => f === mainFacet);
    if (groupName && groupName.length) {
      facetsCount = 1;
      facetGroups = facetGroups.filter((g) => g.slug === groupName);
      const group = facetGroups[0];
      getParams.facetsToFilter = {
        [group.slug]: facets.filter((el) => el.facetGroup === group._id).map((el) => el._id),
      };
      facetGroupMap = {
        [group.slug]: {},
      };
      const { facet } = context.route.params || {};
      if (facet && !['no-minimum', 'closeout'].includes(facet)) {
        let routeFacet = toUrlSlug(context.route.params.facet);
        let facet = facets.find((f) => f.slug && f.slug === routeFacet);
        if (facet) {
          facetGroupMap[groupName][facet._id] = facet._id;
          getParams.facetsToFilter = {
            [group.slug]: [facet._id],
          };
          filterFacets[group.slug] = [facet._id];
        } else {
          getParams.facetsToFilter = {
            [group.slug]: [-1], //no facet
          };
        }
      } else if (context.route.query.selected || context.route.query[context.route.params.slug]) {
        let facetsArray = [];
        (context.route.query.selected || context.route.query[context.route.params.slug])
          .split(',')
          .forEach((fQuery) => {
            let facet = facets.find((f) => f.slug === toUrlSlug(fQuery) && f.facetGroup === group._id);
            if (!facet) return;
            facetGroupMap[groupName][facet._id] = facet._id;
            facetsArray.push(facet._id);
            getParams.facetsToFilter[groupName] = facetsArray;
            filterFacets[group.slug] = facetsArray;
          });
      }
    } else {
      getParams.facetsToFilter = {
        '-1': [], //no facet
      };
    }
    hasFacets = true;
  }
  for (const [key, value] of Object.entries(context.route.query)) {
    const sortBy = sortItems.find((i) => i.label === value || i.query === value);
    if (sortBy) {
      selectedSort = sortBy;
      getParams.sortFilter = selectedSort;
    }
    try {
      if (!key || !value || reservedKeys.indexOf(key.toLowerCase()) > -1) {
        continue;
      }
      const groupName = toUrlSlug(key);
      const group = allFacetGroups.find((g) => g.slug === groupName);
      if (!group) {
        continue;
      }
      if (!facetGroupMap[group.slug]) {
        facetGroupMap[group.slug] = {};
      }
      if (!getParams.facetsToFilter[group.slug]) {
        getParams.facetsToFilter[group.slug] = [];
      }
      const facetsList =
        (value &&
          value
            .toLowerCase()
            .split(',')
            .map(toUrlSlug)) ||
        [];
      if (facetsList.length) {
        const selectedFacets = facets.filter((e) => facetsList.includes(e.slug)).map((e) => e._id);
        Object.assign(
          facetGroupMap[group.slug],
          selectedFacets.map((e) => ({ [e]: e })).reduce((a, b) => ({ ...a, ...b }), {})
        );
        getParams.facetsToFilter[group.slug].push(...selectedFacets);
      }

      hasFacets = true;
    } catch (e) {
      console.log(e);
    }
  }
  getParams.hasFacets = hasFacets;
  return { filterFacets, getParams, selectedSort, facets, facetsCount, facetGroupMap };
};

export { createQuery, createQuery1, setFacetsFilter, setFacetsFilter1, checkFacets };
