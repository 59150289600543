
  export default {
    name: 'GeneralProductView',
    // serverCacheKey() {//
    //   return `GeneralProductView_${Math.floor(Date.now() / 100000)}`;
    // },
    methods: {
      cmpProps(props) {
        const p = { ...props };
        if (!p.categories) {
          p.categories = this.categories;
        }
        return p;
      },
    },
    props: {
      componentName: String,
      supplierData: Object,
      pageQuery: Object,
      parseResources: {
        type: Array,
        default: () => [],
      },
      blogsItems: {
        type: Array,
        default: () => [],
      },
      blogsPagination: Object,
      debugDetails: Object | Array,
      filterFacets: Object,
      loadedAll: Boolean,
      reload: {
        type: Function,
        default: () => 1,
      },
      totalInventory: {
        type: Object | Array,
        default: () => [],
      },
      page: {
        type: Number,
        default: 0,
      },

      updated: {
        type: Number,
        default: 0,
      },
      changeCurrentPage: {
        type: Function,
        default: () => 1,
      },
      reloadAll: {
        type: Function,
        default: () => 1,
      },
      reloadSearch: {
        type: Function,
        default: () => 1,
      },
      pageData: {
        type: Object,
        default: () => {
          page: [];
        },
      },
      selectedSupplier: {
        type: Function,
        default: () => 1,
      },
      selectedFacets: {
        type: Function,
        default: () => 1,
      },
      selectSort: {
        type: Function,
        default: () => 1,
      },
      priceRangeChanged: {
        type: Function,
        default: () => 1,
      },
      clearAllFilters: {
        type: Function,
        default: () => 1,
      },
      totalCloseOut: {
        type: Number,
        default: 0,
      },
      totalNoMinimum: {
        type: Number,
        default: 0,
      },
      favorites: {
        type: Array,
        default: () => [],
      },
      productsList: {
        type: Array,
        default: () => [],
      },
      priceRange: {
        type: Array,
        default: () => [],
      },
      total: {
        type: Number,
        default: 0,
      },
      limit: {
        type: Number,
        default: 0,
      },
      loading: Boolean,
      currentPage: {
        type: Number,
        default: 0,
      },
      currentCategory: {
        type: Object,
        default: () => ({}),
      },
      mainCategory: {
        type: Object,
      },
      filterSuppliers: {
        type: Array | Object,
        default: () => [],
      },
      rangeData: {
        type: Array,
        default: () => [],
      },
      selectedSort: {
        type: Object,
        default: () => ({}),
      },
      sortItems: {
        type: Array,
        default: () => [],
      },
      search: String,
      suppliers: {
        type: Object | Array,
        default: () => ({}),
      },
      groupsWithFacets: {
        type: Array,
        default: () => [],
      },
      facetGroupMap: {
        type: Object,
        default: () => ({}),
      },
      facets: {
        type: Array,
        default: () => [],
      },

      categoriesLimit: {
        type: Number,
        default: 0,
      },
      categories: {
        type: Object | Array,
        default: () => ({}),
      },
      categoriesCount: {
        type: Object,
        default: () => ({}),
      },
      allCategories: {
        type: Array,
        default: () => [],
      },
      categoriesLoading: {
        type: Boolean,
        default: false,
      },
      isPartOfAnotherPage: Boolean,
    },
  };
