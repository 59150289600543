export async function getCategoriesListByProps(storeId, props, getCategories) {
  const { groupByFirstLetter = {}, categoryTypeId, parentCategoryId } = props;

  const params = { limit: 1000, all: 1, orderBy: 'name', ascending: 1, withoutIsExclude: true };

  if (!groupByFirstLetter.enabled) {
    params.hierarchy = true;
  }

  if (categoryTypeId) {
    params.categoryType = categoryTypeId?._id || categoryTypeId;
  } else if (parentCategoryId) {
    params.parentCategory = parentCategoryId;
  }
  if (Array.isArray(params.categoryType)) {
    params.categoryType = params.categoryType[0];
  }
  if (params.categoryType?._id) {
    params.categoryType = params.categoryType._id;
  }

  return (await getCategories(storeId, params)).data || [];
}
