export const parseTags = (tagsToParse, list) => {
  const tags = Object.assign({}, tagsToParse);
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (!item.resource) {
      item.resource = {};
    }
    const el = item.key;
    const pattern = `\\\${${el}}`;
    let patternValue = item.resource[el] || item.resource[item.orkey] || '';
    if (el === 'productName') {
      let firstChar = 0;
      if (patternValue.charAt(0) === ' ') firstChar = 1;
      patternValue = patternValue.charAt(firstChar).toUpperCase() + patternValue.slice(firstChar + 1).toLowerCase();
    }
    if (tags.title) {
      tags.title = tags.title
        .replace(new RegExp(pattern, 'gi'), patternValue)
        .replace(/ +(?= )/g, '')
        .replace(/ \./, '.');
    }
    ['meta', 'link'].forEach((item) => {
      if (Array.isArray(tags[item])) {
        tags[item].forEach((meta) => {
          ['content', 'description', 'href'].forEach((metaEl) => {
            if (meta[metaEl]) {
              meta[metaEl] = meta[metaEl]
                .replace(new RegExp(pattern, 'gi'), patternValue)
                .replace(/ +(?= )/g, '')
                .replace(/ \./, '.');
            }
          });
        });
      }
    });
  }
  return tags;
};
