export async function errorHandler(context, e, defaultStatusCode) {
  const message = e.message || e.data || e.statusText;
  const statusCode = (e.response && e.response.status) || e.status || defaultStatusCode;
  let pageRequest = '';
  try {
    const routes = context.route.path.replace('/', '').split('/');
    const urlSlug = routes.length === 3 ? 'cat-facetgroup' : routes[0] || 'facets';
    const additionalSlug = routes.slice(1).join('/');
    pageRequest = [urlSlug, additionalSlug].filter((e) => e).join('/');
  } catch (e) {}
  if (statusCode === 404) {
    try {
      const { path } = context.route;
      const redirectUrl = path !== '/' ? (path || '').replace(/\/+/, '') : path;
      const { _id } = context.store.getters.storeInformation;
      const { needRedirectToPage } = await context.$api.stores.getPageRedirectUrl(_id, `/${redirectUrl}`);
      if (needRedirectToPage) {
        return context.redirect(301, needRedirectToPage);
      }
    } catch (e) {}
  }
  return context.error({ message, statusCode, pageRequest, statusText: e.response?.data?.message || '' });
}
