import { findMinQuantityPrice } from '@/plugins/productPrices';
import { findQuerySize } from '@/utils/productSizes';
import { mediaHasClassTypeName } from '@/utils/media';
import { CONFIGURATION_TYPE } from '@/utils/productPrices';
const {
  default: {
    methods: { storeProductFundraisingAmount },
  },
} = require('@mtntop/utils/src/mixins/productDiscountMixin');
export const findPriceRange = (data, price) => {
  let priceRange = data.priceRange || '0,0';
  if (priceRange && typeof priceRange === 'string') {
    priceRange = priceRange.split(',').map((e) => parseFloat(e));
  }
  if (price && typeof price === 'string') {
    price = price.split(',').map((e) => parseFloat(e));
  }
  let rangeData = [...Array(Math.floor(priceRange[1]) - Math.floor(priceRange[0])).keys()].map(
    (x) => (x += Math.ceil(priceRange[0]))
  );
  if (!rangeData.includes(priceRange[1])) rangeData.push(priceRange[1]);
  rangeData.unshift(priceRange[0]);
  priceRange = (price || priceRange).map((el) => parseFloat(el));
  if (rangeData[0] < 0) rangeData[0] = 0;
  if (priceRange[0] < 0) priceRange[0] = 0;
  rangeData.forEach((e, index) => (rangeData[index] = parseFloat(e.toFixed(3))));
  priceRange.forEach((e, index) => (priceRange[index] = parseFloat(e.toFixed(3))));
  return { rangeData, priceRange };
};

export const parseTags = async (productData, tags, axios) => {
  try {
    if (productData) {
      const supplier = productData.supplierId ||
        (await axios.get(`/suppliers/byCode/${productData.supplierCode}?onlyCode=1`)).data || {
          psCode: '',
          name: '',
        };
      const categoryName = productData?.category?.name || '';
      const { psCode, name } = supplier;
      const list = [
        {
          key: 'productName',
          orkey: 'productName',
          resource: productData,
        },
        {
          key: 'productId',
          orkey: 'productId',
          resource: productData,
        },
        {
          key: 'supplierCode',
          orkey: 'psCode',
          resource: { psCode },
        },
        {
          key: 'supplierName',
          orkey: 'name',
          resource: { name },
        },
        {
          key: 'supplierFacet',
          orkey: 'supplierFacet',
          resource: { supplierFacet: name },
        },
        {
          key: 'numberOfColors',
          orkey: 'colorsCount',
          resource: productData,
        },
        {
          key: 'minQty',
          orkey: 'minQuantity',
          resource: productData,
        },
        {
          key: 'categoryName',
          orkey: 'categoryName',
          resource: { categoryName },
        },
        {
          key: 'siteName',
          orkey: 'storeName',
          resource: productData,
        },
        {
          key: 'siteLogo',
          orkey: 'logoUrl',
          resource: productData,
        },
        {
          key: 'pageDescription',
          orkey: 'pageDescription',
          resource: {
            pageDescription:
              productData?.description?.content ||
              (productData.pageDescription === tags.meta.find((i) => i.name === 'description')
                ? ''
                : productData.pageDescription),
          },
        },
        {
          key: 'pageTitle',
          orkey: 'pageTitle',
          resource: { pageTitle: productData?.pageTitle || tags.title },
        },
        {
          key: 'closeout',
          orkey: 'closeout',
          resource: { closeout: productData.isCloseout ? 'On Closeout' : '' },
        },

        {
          key: 'no-minimum',
          orkey: 'isNoMinimum',
          resource: { isNoMinimum: productData?.isNoMinimum ? 'No minimum' : '' },
        },
        {
          key: 'pageNumber',
          orkey: 'currentPage',
          resource: { currentPage: productData?.currentPage },
        },
        {
          key: 'facetSuffix',
          resource: {
            facetSuffix: (productData?.selectedFacetsWithNames || [])
              .filter((el) => el.isSuffix)
              .map((el) => el.name)
              .join(' '),
          },
        },
        {
          key: 'facetPrefix',
          resource: {
            facetPrefix: (productData?.selectedFacetsWithNames || [])
              .filter((el) => !el.isSuffix)
              .map((el) => el.name)
              .join(' '),
          },
        },
        {
          key: 'searchTerm',
          orkey: 'search',
          resource: { search: productData?.search },
        },
      ];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (!item.resource) {
          item.resource = {};
        }
        const el = item.key;
        const pattern = `\\\${${el}}`;
        if (item.loadVal) await item.loadVal();
        let patternValue = item.resource[el] || item.resource[item.orkey] || '';
        if (el === 'productName') {
          let firstChar = 0;
          if (patternValue.charAt(0) === ' ') firstChar = 1;
          patternValue =
            patternValue.charAt(firstChar).toUpperCase() + (patternValue.slice(firstChar + 1) || '').toLowerCase();
        }
        if (tags.title) {
          tags.title = tags.title
            .replace(new RegExp(pattern, 'gi'), patternValue)
            .replace(/ +(?= )/g, '')
            .replace(/ \./, '.');
        }
        ['meta', 'link'].forEach((item) => {
          if (Array.isArray(tags[item])) {
            tags[item].forEach((meta) => {
              ['content', 'description', 'href'].forEach((metaEl) => {
                if (meta[metaEl]) {
                  meta[metaEl] = meta[metaEl]
                    .replace(new RegExp(pattern, 'gi'), patternValue)
                    .replace(/ +(?= )/g, '')
                    .replace(/ \./, '.');
                }
              });
            });
          }
        });
      }
      return list;
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const reloadTags = async ({
  context,
  params,
  customer,
  storeName,
  logoUrl,
  productsList,
  categorySlug,
  currentCategory,
  storeUrl,
  urlSlug,
  additionalSlug,
  canonicalPath,
  limit,
  route,
  total,
  page,
}) => {
  const slug = route.params.slug || params.slug;
  const pathMatch = params.pathMatch || route.params.pathMatch;
  const query = route.query;
  if (!currentCategory) currentCategory = {};

  let tags;
  try {
    tags = (
      await context.$api.stores.getPageHeadTags(storeUrl, urlSlug, additionalSlug, {
        params,
        canonicalPath,
        limit,
        total,
        slug,
        categorySlug,
        pathMatch,
        customer,
        currentCategory: {
          name: currentCategory.name,
          _id: currentCategory._id,
        },
        query,
      })
    ).data;
  } catch (e) {
    return context.error({ message: e.data || e.statusText || 'Category Not Found', statusCode: e.status || 404 });
  }
  const defaultDescriptionContent = tags.defaultDescriptionContent;
  const openGrapTags = [];
  tags.meta = [...(tags.meta || []), ...openGrapTags.map((el) => ({ ...el, name: el.property }))];
  const breadcrumbList =
    currentCategory && currentCategory.parents ? [...currentCategory.parents, currentCategory] : [];
  const itemListElement = breadcrumbList.map((cat, index) => ({
    '@type': 'ListItem',
    position: ++index,
    name: cat.name,
    item: `https://${storeUrl}/cat/${cat.slug}`,
  }));
  let structuredData = {};
  let productsStructuredData = [];

  if (productsList) {
    productsStructuredData = productsList.map((product) => ({
      '@type': 'Product',
      image: product.imgUrlSmall || product.imgUrl || product.imageDefault?.urlSmall || product.imageDefault?.url,
      name: product.productName || `${product.productId} ${currentCategory.name}`,
      productId: product.productId,
      category: currentCategory.name,
      offers: {
        '@type': 'Offer',
        priceCurrency: 'USD',
        price: product.priceMax ? product.priceMax.roundCeil() : 0,
        availability: 'InStock',
      },
      sku: product.productId,
      description: product.description,
    }));
    structuredData = {
      '@context': 'http://schema.org',
      '@graph': [
        itemListElement.length > 0
          ? {
              '@type': 'BreadcrumbList',
              itemListElement,
            }
          : null,
        ...productsStructuredData,
      ].filter((i) => !!i),
    };
    if (!productsList.length) {
      let robots = tags.meta.find((e) => e.name === 'robots');
      if (!robots) {
        robots = { name: 'robots', content: '' };
        tags.meta.push(robots);
      }
      robots.content = 'noindex, nofollow';
    }
  } else if (itemListElement.length > 0) {
    structuredData = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement,
    };
  }
  if (!tags.script || !tags.script.length) tags.script = [];
  tags.script.push({ innerHTML: JSON.stringify(structuredData), type: 'application/ld+json' });

  delete tags.defaultDescriptionContent;
  delete tags.description;
  delete tags.descriptionMeta;
  return tags;
};

export const getProductPartsPrices = (parts) => {
  return parts
    .filter((el) => el && el.partGroup === '1' && el.status !== 'discontinued' && el.pricing && el.pricing[0])
    .map((selectedPart) => {
      let res = _.orderBy(selectedPart.pricing, ['minQuantity'], ['asc']);
      return {
        ...selectedPart,
        prices: _.uniqBy(res, (el) => el.minQuantity).filter(
          (i) => (i.unitPrice && i.unitPrice > 0) || (i.price && i.price > 0)
        ),
      };
    });
};
const type = [
  {
    id: '1002',
    name: 'decorated',
  },
  {
    id: '1006',
    name: 'primary',
  },
  {
    id: '1007',
    name: 'front',
  },
  {
    id: '1001',
    name: 'blank',
  },
];
export const getProductFormatedParts = (productData, storeDiscount, query = {}, store = {}) => {
  if (!productData.decorations) productData.decorations = [];
  let selectedDecoration = productData.selectedDecoration;
  if (!selectedDecoration && !productData.isQuoteOnly) {
    selectedDecoration = productData.decorations.find((i) => i.default);
    if (!selectedDecoration || !selectedDecoration.setupChargePrice) {
      selectedDecoration = productData.decorations.find((i) => i.setupChargePrice);
    }
  }
  const setUpChargePrice = selectedDecoration ? parseFloat(selectedDecoration.setupChargePrice) : 0;

  let parts = productData.parts;
  let selectedPart;
  if (query.partId) {
    selectedPart = parts.find((e) => (e.partId || '').toLowerCase() === (query.partId || '').toLowerCase());
  }
  if (query.sizes) {
    let sizes = query.sizes;
    if (!Array.isArray(sizes)) sizes = [query.sizes];
    let _parts = parts.filter((part) => {
      const size = findQuerySize({ sizes }, part);
      if (size) {
        part.size = `sizes=${part.ApparelSize.label_size}-${size[part.ApparelSize.label_size.toLowerCase()]}`;
        part.quantity = parseInt(size[part.ApparelSize.label_size.toLowerCase()]);
      }
      return size;
    });
    if (_parts.length) {
      parts = _parts;
    }
  } else if (selectedPart) {
    parts = [selectedPart];
  }

  let productPartsPrices = getProductPartsPrices(parts);
  productPartsPrices = productPartsPrices
    .map((p) => {
      const { partId, partDescription, colors, mediaList, size } = p;
      const colorName = colors[0] && colors[0].name;
      let image;
      try {
        for (let i = 0; i < type.length; i++) {
          image = (mediaList || []).find((el) => mediaHasClassTypeName(el, type[i].name));
          if (image) {
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
      const partPriceArray = findMinQuantityPrice(p, 0, query.configurationType);
      if (
        query.configurationType === CONFIGURATION_TYPE.Sample &&
        partPriceArray.find((e) => e.configurationType !== CONFIGURATION_TYPE.Sample)
      ) {
        throw `Product page view. No ${CONFIGURATION_TYPE.Sample} prices. Please contact support.!`;
      }
      const quantity = parseFloat(p.quantity || query.quantity || (partPriceArray[0] && partPriceArray[0].minQuantity));
      const priceIndex = partPriceArray.findIndex((partPrice) => parseInt(quantity, 10) >= partPrice.minQuantity);
      const partPrice = partPriceArray[priceIndex === -1 ? partPriceArray.length - 1 : priceIndex];
      let unitPrice = partPrice.unitPrice || 0; //partPrice && partPrice.unitCost ? unitPriceCalc(partPrice) : 0;
      const partDiscount = 0; // storeDiscount ? parseFloat(storeDiscount * unitPrice) : 0;
      if (storeDiscount) {
        unitPrice = (unitPrice - unitPrice * storeDiscount).roundCeil(2);
      }
      const productDiscount = quantity * partDiscount;
      let total =
        quantity * unitPrice +
        (!partPrice.configurationType || partPrice.configurationType === 'Decorated' ? setUpChargePrice : 0) -
        productDiscount;
      if (store) total = storeProductFundraisingAmount(total, store);
      return {
        ...p,
        partPrice: total.roundCeil(2),
        size,
        partId,
        colorName,
        partDescription,
        image,
      };
    })
    .sort((a, b) => a.partPrice - b.partPrice);

  return productPartsPrices;
};
