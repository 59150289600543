import isCloseOut from '@/plugins/isCloseOut';
import isNoMinimum from '@/plugins/isNoMinimum';
const Cookie = process.client ? require('js-cookie') : undefined;

const productsFilterMixin = {
  mixins: [isCloseOut, isNoMinimum],
  data() {
    let filterSuppliers = this.$route.query.suppliersToFilter || [];
    if (!Array.isArray(filterSuppliers)) filterSuppliers = [filterSuppliers];
    return {
      search: null,
      loading: true,
      lastPage: 0,
      categoriesLoading: false,
      loadedAll: false,
      filterSuppliers,
    };
  },
  watch: {
    async currentPage(page) {
      this.reload(page, this.filterSuppliers, this.selectedSort, this.filterFacets);
    },
    '$route.query.closeout'(oldVal, newVal) {
      this.currentPage = 1;
      this.reload(1, this.filterSuppliers, this.selectedSort, this.filterFacets);
    },
  },
  methods: {
    reloadSearch(search) {
      this.search = search;
      this.reloadAll();
    },
    reloadAll() {
      this.currentPage = 1;
      this.reload(1, this.filterSuppliers, this.selectedSort, this.filterFacets);
    },
    selectedFacets(facets) {
      this.currentPage = 1;
      this.reload(1, this.filterSuppliers, this.selectedSort, facets);
    },
    selectSort(val) {
      this.selectedSort = val;
      this.currentPage = 1;
      this.reload(1, this.filterSuppliers, val, this.filterFacets);
    },
    priceRangeChanged(range) {
      this.priceRange = range;
      this.currentPage = 1;
      this.reload(1, this.filterSuppliers, this.selectedSort, this.filterFacets);
    },
    clearAllFilters() {
      this.currentPage = 1;
      this.priceRange = [];
      this.filterFacets = {};
      this.filterSuppliers = [];
      this.search = '';
      this.selectedSort = {};
      this.reload(1, this.filterSuppliers, this.selectedSort, this.filterFacets);
    },
    changeCurrentPage(page) {
      this.currentPage = page;
    },
    async selectedSupplier(suppliers) {
      const page = 1;
      this.currentPage = page;
      this.reload(page, suppliers, this.selectedSort, this.filterFacets);
      this.filterSuppliers = suppliers;
    },
  },
};

export { productsFilterMixin as default };
