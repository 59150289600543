
  import productsFilterMixin from '@/plugins/productsFilterMixin';
  import { mapGetters } from 'vuex';
  import { encodeOpts } from '@/utils/encode-opts';
  import { errorHandler } from '@/utils/error-handler';
  import GeneralProductView from '../GeneralProductView';

  const Cookie = process.client ? require('js-cookie') : undefined;
  import { sortItemsList } from '@/constants';
  import { createQuery, setFacetsFilter, checkFacets } from '@/utils/facets';
  import { findPriceRange, reloadTags } from '../../products/utils';
  import { parseTags } from '@/utils/parseTags';
  import { getBlogPostsListByProps, findComponentOnPage } from '@/utils/components';

  export default {
    name: 'Categories',
    // serverCacheKey() {
    //   return `CategoriesComponent_${Math.floor(Date.now() / 100000)}`;
    // },
    components: {
      GeneralProductView,
    },
    mixins: [productsFilterMixin],
    head() {
      return this.tags;
    },
    watchQuery: true,
    async asyncData(context) {
      try {
        const debugDetails = [{ service: process.env.NEWRELIC_APP_NAME, spent: Date.now() }];
        const sortItems = sortItemsList(context.store.getters.storeInformation);
        let isCloseout = false;
        let isNoMinimum = false;
        let limit = 40;
        let filterSuppliers = [];
        let rangeData = [];
        let search = context.route.query.query;
        const routes = context.route.path.replace('/', '').split('/');
        const urlSlug = routes.filter((i) => i !== 'closeout').length === 3 ? 'cat-facetgroup' : routes[0] || 'facets'; //if cat/apparel/sun-protection then urlSlug should be cat-facetgroup
        const additionalSlug = routes.slice(1).join('/');
        let { page, suppliersToFilter, price } = context.route.query;
        const slug = context.route.params.slug || context.route.params.pathMatch;
        const { storeUrl, storeName, logoUrl, _id } = context.store.getters.storeInformation;
        const customer = context.store.getters['auth/loggedInUser'];
        const { query } = context.route;
        let result;

        const pageDetailsOptions = {
          storeUrl,
          urlSlug,
          additionalSlug,
          categorySlug: slug,
          query,
          customer,
        };

        let pageData = (await context.$api.stores.getStoreSlugPage(pageDetailsOptions)).data;

        let hasProductsList = false;
        let currentCategory;
        let pageFilters;
        for (const cmp of pageData) {
          if (cmp.name.includes('Categories')) {
            hasProductsList = true;
            pageFilters = cmp.props?.categories?.filters;
            if (cmp.props.products?.limit) {
              limit = parseInt(cmp.props.products.limit, 10);
              break;
            }
          }
        }
        const currentPage = parseInt(page, 10) || 1;

        const { inventory } = query;
        const params = {
          limit,
          inventory: inventory || '',
          page: parseInt(page) || 1,
          facetsToFilter: {},
        };
        const { filterFacets, getParams, selectedSort, facets, facetGroupMap } = await setFacetsFilter(
          context,
          params,
          sortItems
        );
        if (context.route.params.pathMatch.includes('search/')) {
          search = context.route.params.pathMatch.replace('search/', '');
        }
        if (search) {
          // search = search.split('-').join(' ');
          getParams.query = search;
        }
        if (context.route.params.pathMatch === 'no-minimum' || context.route.query['no-minimum']) {
          isNoMinimum = true;
          getParams.filters = { minQuantity: 1 };
        }
        if (context.route.params.pathMatch === 'closeout' || context.route.query.closeout) {
          isCloseout = true;
          if (!getParams.filters) getParams.filters = {};
          Object.assign(getParams.filters, { isCloseout });
        } else if (context.route.params.pathMatch.includes('supplier/')) {
          const selected = context.route.params.pathMatch.split('/')[
            context.route.params.pathMatch.split('/').length - 1
          ];
          suppliersToFilter = [selected.toUpperCase()];
        }
        if (suppliersToFilter) {
          getParams.suppliersToFilter = filterSuppliers = suppliersToFilter;
        }

        let total,
          suppliers,
          totalInventory,
          groupsWithFacets = [],
          productsList,
          priceRange,
          totalNoMinimum,
          totalCloseOut;

        if (price) {
          getParams.priceRange = price;
        }

        getParams.populateCurrentCategoryType = true;
        if (pageFilters) getParams.pageFilters = pageFilters;
        let mainCategory;
        if (hasProductsList) {
          const res = (
            await context.$axios.get(`/stores/${_id}/categories/${slug}/productItems`, {
              params: getParams,
              paramsSerializer: encodeOpts,
            })
          ).data;
          if (res.requestQuery) console.log('requestQuery', JSON.stringify(res.requestQuery, null, 2));
          debugDetails.push(res.debugDetails);
          totalNoMinimum = res.totalNoMinimum || 0;
          totalCloseOut = res.totalCloseOut || 0;
          total = res.total || 0;
          totalInventory = res.totalInventory;
          suppliers = res.suppliers || [];
          groupsWithFacets = res.groupsWithFacets || [];
          productsList = res.productsList || [];
          let d = findPriceRange(res, price);
          priceRange = d.priceRange || [];
          rangeData = d.rangeData || [];
          currentCategory = res.currentCategory || {};
          mainCategory = res.mainCategory || { name: urlSlug };
          checkFacets({ facetGroupMap, facets, filterFacets }, groupsWithFacets);
          // console.log('productsList', productsList);
        } else {
          currentCategory = (await context.$axios.get(`/categories/${slug}`)).data;
        }
        currentCategory.childCategories = _.orderBy(currentCategory.childCategories, ['name'], ['asc']);
        let suppliersWithNames = [];
        if (suppliersToFilter && suppliersToFilter.length && suppliers) {
          if (Array.isArray(suppliersToFilter)) {
            const t = suppliersToFilter.map((el) => el && el.toLowerCase());
            suppliersWithNames = suppliers
              .filter((el) => el.psCode && t.indexOf(el.psCode.toLowerCase()) > -1)
              .map((el) => el.name);
          } else {
            suppliersWithNames = suppliers
              .filter(
                (el) => el.psCode && suppliersToFilter && el.psCode.toLowerCase() === suppliersToFilter.toLowerCase()
              )
              .map((el) => el.name);
          }
        }
        let tags = await reloadTags({
          customer,
          params: getParams,
          categorySlug: slug,
          canonicalPath: 'cat',
          storeName,
          logoUrl,
          productsList,
          currentCategory,
          storeUrl,
          urlSlug,
          additionalSlug,
          limit,
          total,
          page,
          context,
          route: context.route,
        });
        const description = (tags.meta || []).find((i) => i.name === 'description');
        const facetsIds = [].concat.apply(
          [],
          [].concat.apply([], Object.values(facetGroupMap)).map((i) => {
            return Object.values(i);
          })
        );
        const facetsObjects = [].concat.apply(
          [],
          groupsWithFacets.map((group) => [].concat.apply([], group.facets))
        );
        const selectedFacetsWithNames = facetsIds
          .map((id) => {
            return facetsObjects.find((f) => f._id === id);
          })
          .filter((f) => f);
        const parseResources = [
          {
            key: 'categoryName',
            orkey: 'name',
            resource: currentCategory,
          },
          {
            key: 'siteName',
            orkey: 'storeName',
            resource: { storeName },
          },
          {
            key: 'siteLogo',
            orkey: 'logoUrl',
            resource: { logoUrl },
          },
          {
            key: 'pageNumber',
            orkey: 'currentPage',
            resource: { currentPage },
          },
          {
            key: 'searchTerm',
            orkey: 'search',
            resource: { search },
          },
          {
            key: 'closeout',
            orkey: 'isCloseout',
            resource: { isCloseout: isCloseout ? 'On Closeout' : '' },
          },
          {
            key: 'no-minimum',
            orkey: 'isNoMinimum',
            resource: { isNoMinimum: isNoMinimum ? 'No minimum' : '' },
          },
          {
            key: 'supplierFacet',
            orkey: 'suppliersToFilter',
            resource: { suppliersToFilter: suppliersWithNames.map((i) => i).join(', ') },
          },
          {
            key: 'facetSuffix',
            resource: {
              facetSuffix: selectedFacetsWithNames
                .filter((el) => el.isSuffix)
                .map((el) => el.name)
                .join(' '),
            },
          },
          {
            key: 'facetPrefix',
            resource: {
              facetPrefix: selectedFacetsWithNames
                .filter((el) => !el.isSuffix)
                .map((el) => el.name)
                .join(' '),
            },
          },
          {
            key: 'facetGroup',
            resource: {
              facetGroup: (groupsWithFacets.find((i) => additionalSlug.includes(i.slug)) || { name: '' }).name,
            },
          },
          {
            key: 'pageDescription',
            orkey: 'pageDescription',
            resource: { pageDescription: description.content },
          },
          {
            key: 'pageTitle',
            orkey: 'pageTitle',
            resource: { pageTitle: tags.title },
          },
        ];
        tags = parseTags(tags, parseResources);

        const data = {};

        const blogPostsList = findComponentOnPage('BlogPostsList', pageData);
        if (blogPostsList) {
          data.blogList = (
            await getBlogPostsListByProps(
              _id,
              blogPostsList.props,
              { categories: [currentCategory], availableCategoryTypes: ['products'] },
              context.$api.stores.getStorePages
            )
          ).data;
        }
        const blogList = findComponentOnPage('BlogList', pageData);
        let blogsItems, blogsPagination;
        if (blogList) {
          const { data, pagination } = await context.$api.stores.getStorePagesByCategory(currentCategory._id, {
            page: page || 1,
            storeId: _id,
          });
          blogsItems = data;
          blogsPagination = pagination;
        }
        result = {
          debugDetails,
          blogsItems,
          blogsPagination,
          totalInventory,
          updated: Date.now(),
          loaded: true,
          loading: false,
          lastPage: page,
          filterSuppliers,
          totalCloseOut,
          totalNoMinimum,
          tags,
          pageData: {
            page: pageData,
            data,
          },
          productsList,
          priceRange,
          total,
          limit,
          currentPage,
          mainCategory,
          currentCategory,
          rangeData,
          selectedSort,
          sortItems,
          search,
          suppliers,
          groupsWithFacets,
          facetGroupMap,
          facets,
          filterFacets,
          parseResources,
        };
        if (!result.totalNoMinimum) result.totalNoMinimum = 0;
        if (!result.totalCloseOut) result.totalCloseOut = 0;
        if (!result.productsList) result.productsList = [];
        if (!result.priceRange) result.priceRange = [];
        if (!result.suppliers) result.suppliers = [];
        if (!result.totalInventory) result.totalInventory = [];
        if (!result.total) result.total = 0;
        if (!result.lastPage) result.lastPage = 1;

        debugDetails[0].spent = Date.now() - debugDetails[0].spent;
        return result;
      } catch (e) {
        console.log(e);
        return await errorHandler(context, e);
      }
    },
    computed: {
      ...mapGetters({
        customer: 'auth/loggedInUser',
        favorites: 'favorites/customerFavorites',
      }),
    },
    methods: {
      async reload(page, suppliersToFilter, sortFilter, facetsFilter, additionalParams = {}) {
        if (this.loading) return;

        const isCloseOut = 'closeout' in additionalParams ? additionalParams.closeout !== 'no' : this.isCloseOut;
        const isNoMinimum =
          'no-minimum' in additionalParams ? additionalParams['no-minimum'] !== 'no' : this.isNoMinimum;
        let { facetsQuery, facetsToFilter } = createQuery(facetsFilter, this.facets);
        let query = {
          page,
          inventory: this.$route.query.inventory || '',
          price: this.priceRange,
          name: sortFilter.query || sortFilter.label,
        };
        if (isCloseOut) {
          query.closeout = 'yes';
        }
        if (isNoMinimum) {
          query['no-minimum'] = 'yes';
        }

        this.filterFacets = facetsToFilter;
        const isSingleSupplier = (suppliersToFilter || []).length === 1;
        query = {
          ...facetsQuery,
          ...query,
          suppliersToFilter: isSingleSupplier ? undefined : suppliersToFilter,
          ...additionalParams,
        };
        if (this.search) {
          query.query = this.search;
        } else {
          delete query.query;
        }
        if (query.query) query.query = encodeURI(query.query.trim()).replace('%20', ' ');
        return this.$router.push({
          path:
            `/cat/${this.currentCategory.slug}` +
            (!Object.keys(query).length ? (this.search ? '/search/' + this.search : '') : '') +
            (isSingleSupplier ? '/supplier/' + suppliersToFilter[0].toLowerCase() : ''),
          query,
        });
      },
    },
  };
