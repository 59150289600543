export async function getBlogPostsListByProps(
  storeId,
  props = {},
  pageData = {},
  getStorePages,
  { urlSlug, getThemeCategories } = {},
  page,
  sortBy,
  paramsMore
) {
  if (!props.limit) props.limit = 20;
  const { categories, categoryType } = props;
  const { availableCategoryTypes } = pageData;
  const params = {
    ...paramsMore,
    limit: +props.limit,
    page,
    storeId,
    isEnabled: true,
    type: [{ val: `^blog` }],
    requiredFields: paramsMore?.requiredFields || ['pageTitle', 'media', 'urlSlug', 'pageDescription', 'withAuthor'],
  };

  if (categoryType) {
    params.type = [{ val: `^${categoryType}` }];
  }

  const getCategoriesIds = (categories) => {
    return categories
      .filter((c) => c)
      .map((c) => {
        if (c?.categoryType && !(availableCategoryTypes || ['blog', 'themes']).includes(c?.categoryType?.slug)) {
          return null;
        }
        return c?.id || c?._id;
      })
      .filter((id) => !!id);
  };

  if (categories?.length > 0) {
    params.categories = getCategoriesIds(categories);
  } else if (pageData.categories?.length > 0 && !pageData.categories.includes(undefined)) {
    params.categories = getCategoriesIds(pageData.categories);
  } else if (categoryType) {
    params.withAnyCategories = true;
  } else if (urlSlug) {
    params.categories = await getThemeCategories({ slugs: [urlSlug] });
  }

  params.orderBy = sortBy;

  return getStorePages(storeId, params);
}
